import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import "./assets/css/default.css";
import InputData from "../Data/InputData.json";
import NftLoot from "../../abis/NftLoot.json";
import Web3 from "web3";

const LandingPageIndex = () => {
  const [darkMode, setDarkMode] = React.useState(true);

  const [nftLoot, setNftLoot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenURIs, setTokenURLs] = useState([]);
  const [data, setData] = useState([]);
  const [totalSupply, setTotalSupply] = useState("");
  const [startFetching, setStartFetching] = useState(false);
  const [fetchCount, setFetchCount] = useState(2);
  const [initCount, setInitCount] = useState(0);
  const [noMoreData, setNoMoreData] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);

  useEffect(() => {
    loadWeb3();
  }, []);

  const loadWeb3 = async () => {
    setStartFetching(true);
    if (window.ethereum) {
      window.web3 = new Web3(
        "https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017"
      );
      // await window.ethereum.enable();
      loadBlockchainData();
      return true;
    } else if (window.web3) {
      window.web3 = new Web3(
        "https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017"
      );
      loadBlockchainData();
      return true;
    } else {
      // window.alert(
      //  "Non-Ethereum browser detected. You should consider trying MetaMask!"
      // );
      window.web3 = new Web3(
        "https://polygon-mainnet.infura.io/v3/b3eedf1cfbf5489c82fb477496327017"
      );
      loadBlockchainData();
      return true;
    }
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;
    // Network ID
    const networkId = await web3.eth.net.getId();
    const networkData = NftLoot.networks[137];
    if (networkData) {
      console.log("networkdata", networkData);
      const nftLoot = new web3.eth.Contract(
        NftLoot.abi,
        InputData.contract_address
      );
      setNftLoot(nftLoot);
      const totalSupply = await nftLoot.methods.totalSupply().call();
      setTotalSupply(totalSupply);
      // fetch tokenURI.
      fetchDatafromBlockchain(nftLoot, totalSupply);
      setStartFetching(false);
    } else {
      window.alert("Contract not deployed to detected network.");
    }
  };

  const loadMore = (event) => {
    event.preventDefault();
    fetchDatafromBlockchain(nftLoot, totalSupply);
    setLoadMoreLoading(true);
  };

  const fetchDatafromBlockchain = async (nftLoot, totalSupply) => {
    console.log("initial count", initCount);
    console.log("fetch count", fetchCount);
    const tempFetchCount = fetchCount;
    const tempInitCount = initCount;
    // Load images
    let loopCount = 0;
    if (initCount <= totalSupply) {
      for (var i = tempInitCount; i <= tempFetchCount; i++) {
        if (i <= totalSupply) {
          // get the token ID.
          const tokenID = await nftLoot.methods.tokenByIndex(i).call();
          if (tokenID) {
            // get the token URL
            const tokenURI = await nftLoot.methods.tokenURI(tokenID).call();
            const response = await fetch(tokenURI);
            const jsonData = await response.json();
            setData((prevState) => [...prevState, jsonData]);
            setTokenURLs((prevState) => [...prevState, tokenURI]);
          }
          loopCount++;
        } else {
          console.log("error mssg");
          setNoMoreData(true);
        }
      }

      console.log("asdfasdf", loopCount);
      setInitCount((prevState) => prevState + loopCount);
      setFetchCount((prevState) => prevState + 6);
    } else {
      // return true;
      // Errror msg
      console.log("eeror mssg");
    }
    setLoading(false);
    setLoadMoreLoading(false);
  };

  return (
    <>
      <section className="nft-keys-sec">
        <Container>
          <Row>
            <div className="row margin-top-lg align-items-center">
              <div className="col-md-6">
                <div className="nft-brand-info">
                  <h2>{InputData.banner_title}</h2>
                  <h4>3,350/3,350 minted</h4>
                  <p>{InputData.banner_description}</p>
                  <h3>SOLD OUT</h3>
                </div>
                <div className="nft-social-share-sec">
                  <ul className="nft-social-share-list list-unstyled">
                    <li>
                      <a href={InputData.opensea_link}>OpenSea</a>
                    </li>
                    <li>
                      <a href={InputData.contract_address}>Contract</a>
                    </li>
                  </ul>
                  <a href="#" className="follow-us-btn">
                    Follow Us On
                  </a>
                  <ul className="nft-social-share-link list-unstyled">
                    {InputData.follow_icons.length > 0
                      ? InputData.follow_icons.map((social) => {
                        return (
                          <li>
                            <a href={social.link}>
                              <img
                                className="social-icons"
                                src={social.icon}
                                alt=""
                              />
                            </a>
                          </li>
                        );
                      })
                      : ""}
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="nft-loot-banner-img-sec">
                  <img src={InputData.img_cartoon} />
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className="sm-padding nft-key-img-sec">
        <Container>
          <h2 className="image-title">Example NFT’s</h2>
          <div className="row">
            {loading
              ? "Loading..."
              : data.length > 0
                ? data.map((value, index) => {
                  return (
                    <div className="col-md-4 col-sm-12 mt-3">
                      <div className="nft-loot-box">
                        <Link to={`/assets/${InputData.contract_address}/${index + 1}`}>
                          <div className="nft-loot-card">
                            <div className="nft-loot-img-sec">
                              <div className="nft-loot-img-sec">
                                <img src={value.image} />
                              </div>
                            </div>
                            <p>{value.name}</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })
                : ""}
          </div>
          {loadMoreLoading ? "Loading..." : ""}
          <div>
            <div className="col-md-12">
              <div className="nft-loot-load-more-btn-sec">
                {noMoreData ? (
                  "No More Data"
                ) : (
                  <a href="#" className="load-more-btn" onClick={loadMore}>
                    Load More
                  </a>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default LandingPageIndex;
